<template>
  <item :item="item">
    <template #status2>已取消</template>
    <template #operate>
      <more>
        <more-item @click="p.orderAgain(item)">再来一单</more-item>
      </more>
      <div
        class="button button-4"
        @click="p.deleteOrder(item)"
      >删除订单</div>
    </template>
  </item>
</template>

<script>
import Item from './item.vue';
import More from "./more.vue";
import MoreItem from './more-item.vue';
export default {
  components: {
    Item,
    More,
    MoreItem,
  },
  props: {
    //订单详情
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.button {
  font-size: 26px;
  line-height: 37px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 41px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  &:nth-of-type(1) {
    margin-left: 16px;
  }
}
.button-2 {
  color: #1aa3ff;
  border: 1px solid #1aa3ff;
}
.button-4 {
  color: #F65C5C;
  border: 1px solid #F65C5C;
}
</style>
