<template>
  <item :item="item">
    <template #status>付款中</template>
    <template #operate>
      <div
        class="button button-2"
        @click="p.orderAgain(item)"
      >再来一单</div>
    </template>
    <template #bottomExplain>
      <!-- 非失败 -->
      <template v-if="item.linePayStatus != 3 && item.generalPayStatus != 3">
        <!-- 普通支付 -->
        <div
          class="explain"
          v-if="item.type == 0"
        >{{generalPayStatusEnum[item.generalPayStatus]}}</div>
        <!-- 授信支付 -->
        <div
          class="explain"
          v-if="item.type == 1"
        >{{linePayStatusEnum[item.linePayStatus]}}</div>
        <!-- 组合支付 -->
        <div
          class="explain"
          v-if="item.type == 2"
        >{{linePayStatusEnum[item.linePayStatus]}}，{{generalPayStatusEnum[item.generalPayStatus]}}</div>
      </template>
      <!-- 失败 -->
      <template v-else>
        <!-- 普通支付 -->
        <div
          class="explain2"
          v-if="item.type == 0"
        >
          {{generalPayStatusEnum[item.generalPayStatus]}}<template v-if="item.generalFailedReason">（{{item.generalFailedReason}}）</template>
        </div>
        <div
          class="explain2"
          v-if="item.type == 1"
        >
          {{linePayStatusEnum[item.linePayStatus]}}<template v-if="item.linePayFailedReason">（{{item.linePayFailedReason}}）</template>
        </div>
        <div
          class="explain2"
          v-if="item.type == 2"
        >
          {{linePayStatusEnum[item.linePayStatus]}}<template v-if="item.linePayFailedReason">（{{item.linePayFailedReason}}）</template>，
          {{generalPayStatusEnum[item.generalPayStatus]}}<template v-if="item.generalFailedReason">（{{item.generalFailedReason}}）</template>
        </div>
        <!-- <div class="explain3">如有疑问请致电联系平台客服：{{config.ServiceTel}}</div> -->
      </template>
    </template>
  </item>
</template>

<script>
import Item from './item.vue';
import config from "@/config/index.js";
export default {
  components: {
    Item,
  },
  props: {
    //订单详情
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  inject: ['p'],
  data() {
    return {
      config,
      linePayStatusEnum: { 1: '授信支付：银行拨款中', 2: '授信支付：银行已拨款', 3: '授信支付：银行拨款失败' },
      generalPayStatusEnum: { 1: '自付支付：凭证审核中', 2: '自付支付：凭证审核通过', 3: '自付支付：凭证审核失败' },
    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.button {
  font-size: 26px;
  line-height: 37px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 41px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}
.button-2 {
  color: #1aa3ff;
  border: 1px solid #1aa3ff;
}
.explain {
  border-top: 1px solid #eeeeee;
  padding: 17px 30px 0px 30px;
  margin-top: 17px;
  font-size: 24px;
  font-weight: 400;
  color: #b2b2b2;
}
.explain2 {
  border-top: 1px solid #eeeeee;
  padding: 17px 30px 0px 30px;
  margin-top: 21px;
  font-size: 24px;
  font-weight: 400;
  color: #ff7d7d;
}
.explain3 {
  padding: 0px 30px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #b2b2b2;
}
</style>
