<template>
  <item :item="item">
    <template #status>待付款</template>
    <template #operate>
      <template >
        <more>
          <more-item @click="p.orderAgain(item)">再来一单</more-item>
          <!-- 授信支付状态为待付款 或 普通支付，待付款或待上传付款凭证 -->
          <more-item 
            v-if="([1].indexOf(item.type) == -1 && item.payType == 1) || (item.type == 0 && [1,2].indexOf(item.payType) != -1)" 
            @click="p.cancelOrder(item)"
          >取消订单</more-item>
        </more>
      </template>
      <div
        class="button button-2"
        v-if="item.payType == 1"
        @click="p.goPay(item)"
      >去付款</div>
      <div
        class="button button-2"
        v-else-if="item.payType == 2"
        @click="p.goUpload(item)"
      >上传付款凭证</div>
    </template>
  </item>
</template>

<script>
import Item from './item.vue';
import More from "./more.vue";
import MoreItem from './more-item.vue';
export default {
  components: {
    Item,
    More,
    MoreItem,
  },
  props: {
    //订单详情
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  inject: ['p'],
  data() {
    return {
      
    };
  },
  mounted() {

  },
  methods: {
    orderAgain(item) {
      this.showPopover = false;
      this.$nextTick(() => {
        this.p.orderAgain(item);
      })
    }
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
// .more-list {
//   .more-item {
//     padding: 20px 30px;
//     font-size: 24px;
//     font-weight: 400;
//     color: #666666;
//   }
// }

// .more {
//   font-size: 26px;
//   line-height: 38px;
//   font-weight: 400;
//   color: #999999;
// }
.button {
  font-size: 26px;
  line-height: 37px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 41px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  &:nth-of-type(1) {
    margin-left: 16px;
  }
  &.no-left {
    margin-left: 0;
  }
}

.button-1 {
  color: #999999;
  border: 1px solid #999999;
}
.button-2 {
  color: #1aa3ff;
  border: 1px solid #1aa3ff;
}
</style>
