<template>
  <div class="container">
    <navbar title="订单管理"></navbar>
    <c-sticky :offset-top="$px2vwMin(92)">
      <search-input2
        placeholder="搜索订单"
        v-model="keyword"
        @search="search"
      ></search-input2>
    </c-sticky>
    <c-sticky :offset-top="$px2vwMin(202)">
      <van-tabs
        v-model="tab"
        color="#006AFA"
        :line-width="$px2vw(58)"
        :line-height="$px2vw(6)"
        title-active-color="#006AFA"
        title-inactive-color="#999999"
        @click="changeTab"
      >
        <van-tab
          title-class="tab-item"
          v-for="(item,i) in tabList"
          :title="item.name"
          :name="item.status"
          :key="i"
          :to="'/order/'+(item.status == -1 ? '' : item.status) +'?_replace=true'"
        >
        </van-tab>
      </van-tabs>
    </c-sticky>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, i) in list" :key="i">
          <!-- 普通支付待付款 -->
          <item1
            :item="item"
            v-if="item.order_status == 0"
          ></item1>
          <!-- 付款中 -->
          <item2
            :item="item"
            v-if="item.order_status == 1"
          ></item2>
          <!-- <item2-credit :item="item"></item2-credit> -->
          <!-- 待发货 -->
          <item3
            :item="item"
            v-if="item.order_status == 2 || item.order_status == 3"
          ></item3>
          <!-- 待收货 -->
          <item4
            :item="item"
            v-if="item.order_status == 4"
          ></item4>
          <!-- 已完成 -->
          <item5
            :item="item"
            v-if="item.order_status == 5"
          ></item5>
          <!-- 已取消 -->
          <item6
            :item="item"
            v-if="item.order_status == 6"
          ></item6>
        </div>
      </van-list>
    </div>
    <confirm
      v-model="confirmShow"
      title="确认删除订单？"
      @confirm="confirmDelete"
    ></confirm>
    <confirm
      v-model="cancelOrderShow"
      title="确认取消订单？"
      confirmButtonText="取消订单"
      cancelButtonText="我再想想"
      @confirm="confirmCancelOrder"
    >
    </confirm>
  </div>
</template>

<script>
import CSticky from "@/components/c-sticky.vue";
import Navbar from "@/components/navbar.vue";
import SearchInput2 from '@/components/search-input2.vue';
import Item1 from './components/item1.vue';
import Item2 from './components/item2.vue';
import Item3 from './components/item3.vue';
import Item4 from './components/item4.vue';
import Item5 from './components/item5.vue';
import Item6 from './components/item6.vue';
import { orderList, orderCancel, orderConfirm, orderDelete } from "@/api/order.js";
import Confirm from '@/components/confirm.vue';
export default {
  name: "order",
  components: {
    CSticky,
    Navbar,
    SearchInput2,
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Confirm,
  },
  provide() {
    return {
      p: this,
    };
  },
  data() {
    return {
      tab: '-1',
      tabList: [
        { name: '全部', status: -1, data: -1 },
        { name: '待付款', status: 0, data: [0] },
        { name: '付款中', status: 1, data: [1] },
        { name: '待发货', status: 2, data: [2, 3] },
        { name: '待收货', status: 4, data: [4] },
        { name: '已完成', status: 5, data: [5] },
        { name: '已取消', status: 6, data: [6] }
      ],
      list: [],
      loading: false,
      finished: false,
      keyword: '',
      tmpDeleteItem: {},
      tmpCancelItem: {},
      confirmShow: false,
      cancelOrderShow: false,
    };
  },
  mounted() {
    if (this.$route.params != undefined && this.$route.params.status != undefined) {
      this.tab = Number(this.$route.params.status);
    }
    this.search();
    // console.log('mounted');

  },
  methods: {
    search() {
      this.page = 0;
      this.finished = false;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      this.page++;
      let type = this.tabList[0]['data'];
      let index = this.tabList.map(item => item.status).indexOf(this.tab);
      if (index != -1) type = this.tabList[index]['data'];
      let data = { page: this.page, keywords: this.keyword, type: type };
      orderList(data).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) {
            this.finished = true;
          }
          if (this.page == 1) {
            this.list = res.data;
          } else {
            this.list = [...this.list, ...res.data];
          }
          this.loading = false;
          // console.log('list:', this.list);
        } else {
          this.$toast(res.msg);
        }
      })
    },
    changeTab() {
      this.search();
    },
    //取消订单
    cancelOrder(item) {
      this.tmpCancelItem = item;
      this.cancelOrderShow = true;
    },
    //确认取消订单
    confirmCancelOrder() {
      orderCancel({ id: this.tmpCancelItem.id }).then(res => {
        if (res.code == 0) {
          this.search();
          this.tmpCancelItem = {};
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //再来一单
    orderAgain(item) {
      this.$router.push({ name: 'orderAdd', query: { supplier_id: item.supplier_id, toy_id: item.toy_id, num: item.purchase_quantity } })
    },
    //确认收货
    orderConfirm(item) {
      orderConfirm({ id: item.id }).then(res => {
        if (res.code == 0) {
          this.search();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //确认删除？
    deleteOrder(item) {
      this.tmpDeleteItem = item;
      this.confirmShow = true;
    },
    //确认删除
    confirmDelete() {
      orderDelete({ id: this.tmpDeleteItem.id }).then(res => {
        if (res.code == 0) {
          this.search();
          this.tmpDeleteItem = {};
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //去支付
    goPay(item) {
      this.$router.push('/pay/' + item.id);
    },
    //去上传支付凭证
    goUpload(item) {
      this.$router.push('/pay/voucher/' + item.id);
    },
  },
  computed: {

  },
  watch: {
    // '$route': {
    //   deep: true,
    //   immediate: true,
    //   handler(to, from) {
    //     let newTab = '';
    //     // console.log('status：' + to?.params?.status);
    //     if (to?.params?.status != undefined) {
    //       newTab = Number(to.params.status);
    //     } else {
    //       newTab = this.tabList[0]['status'];
    //     }
    //     // console.log(this.tab, newTab);
    //     if (this.tab != newTab) {
    //       this.tab = newTab;
    //       this.search();
    //     }
    //   },
    // }
  }
};
</script>
<style lang="scss" scoped>
.container {
  :deep(.tab-item) {
    padding: 0 16px;
    font-size: 26px;
    font-weight: 500;
  }
  :deep(.van-tab--active) {
    font-weight: bold;
  }
  :deep(.van-tabs__nav--line.van-tabs__nav--complete) {
    padding-left: 3px;
    padding-right: 3px;
  }
  .list {
    @include safe-area-inset-bottom;
    :deep(.van-list__finished-text) {
      margin: 0 20px;
    }
  }
}
</style>
