<template>
  <div class="order-item-container">
    <div class="order-name-status" @click="$router.push('/order/detail/'+item.id)">
      <div class="factory-name">{{item.supplier_name}}</div>
      <div class="order-status">
        <slot name="status"></slot>
        <span class="order-status2">
          <slot name="status2"></slot>
        </span>
      </div>
    </div>
    <div class="toy-item" @click="$router.push('/order/detail/'+item.id)">
      <van-image
        class="toy-img"
        fit="contain"
        :src="item.toy_thumbnail"
      />
      <div class="toy-content">
        <div class="toy-name">{{item.toy_name}}</div>
        <div class="toy-number">型号：{{item.toy_article_number}}</div>
      </div>
      <div class="toy-right">
        <div class="toy-price">￥{{item.purchase_price}}</div>
        <div class="toy-num">×{{item.purchase_quantity}}</div>
      </div>
    </div>
    <div class="bottom-operate">
      <div class="bottom-operate-button">
        <slot name="operate"></slot>
      </div>
      <div class="total">
        <span class="total-text">合计</span>
        <span class="total-unit">￥</span>
        <span class="total-num">{{item.order_amount}}</span>
      </div>
    </div>
    <div class="bottom-explain">
      <slot name="bottomExplain">
        <div :style="{height: $px2vw(7)}"></div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "order-item",
  components: {

  },
  props: {
    //订单详情
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.order-item-container {
  margin: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 10px;
  padding: 26px 0;
  .order-name-status {
    padding: 0 33px 0 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    .factory-name {
      font-size: 30px;
      font-weight: bold;
      color: #4f4f4f;
    }
    .order-status {
      color: #ff9700;
      font-size: 30px;
      font-weight: 400;
    }
    .order-status2 {
      color: #999999;
    }
  }
  .toy-item {
    padding: 0 33px 0 30px;
    display: flex;
    .toy-img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      overflow: hidden;
      flex-shrink: 0;
    }
    .toy-content {
      flex: 1;
      overflow: hidden;
      margin-left: 26px;
      margin-right: 26px;
      .toy-name {
        font-size: 30px;
        font-weight: 500;
        color: #4f4f4f;
        @include not-wrap;
        margin-top: 9px;
      }
      .toy-number {
        font-size: 26px;
        font-weight: 400;
        color: #999999;
        @include not-wrap;
        margin-top: 4px;
      }
    }
    .toy-right {
      flex-shrink: 0;
      text-align: right;
      font-size: 26px;
      font-weight: 400;
      color: #999999;
      .toy-price {
        margin-top: 12px;
      }
      .toy-num {
        margin-top: 6px;
      }
    }
  }
  .bottom-operate {
    padding: 0 33px 0 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .bottom-operate-button {
      display: flex;
      align-items: center;
    }
    .total {
      color: #f33636;
      height: 47px;
      display: flex;
      align-items: flex-end;
      .total-text {
        font-size: 30px;
        font-weight: 500;
        color: #666666;
        margin-right: 4px;
      }
      .total-unit {
        font-size: 26px;
        font-weight: 500;
      }
      .total-num {
        font-size: 36px;
        line-height: 38px;
        font-weight: bold;
      }
    }
  }
}
</style>
