<template>
  <div
    class="more-item"
    @click="clickClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    
  },
  inject: ['_more'],
  methods:{
    clickClick(){
      this._more.show = false;
      setTimeout(() => {
        this.$emit('click');
      }, 200)
    }
  },
  computed: {
    
  },
}
</script>

<style lang="scss" scoped>
.more-item {
  padding: 20px 30px;
  font-size: 24px;
  font-weight: 400;
  color: #666666;
}
</style>