<template>
  <div class="c-sticky" :style="{top: offsetTop, 'z-index': zIndex}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    'offset-top': {
      default: 0,
      type: [String, Number]
    },
    'z-index':{
      default: 99,
      type: [String, Number]
    }
  },
  data() {
    return {
      
    }
  },
  watch: {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.c-sticky{
  position:sticky;
}
</style>