<template>
  <item :item="item">
    <template #status>待发货</template>
    <template #operate>
      <div
        class="button button-2"
        @click="p.orderAgain(item)"
      >再来一单</div>
    </template>
  </item>
</template>

<script>
import Item from './item.vue';
export default {
  components: {
    Item,
  },
  props: {
    //订单详情
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.button {
  font-size: 26px;
  line-height: 37px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 41px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}
.button-2 {
  color: #1aa3ff;
  border: 1px solid #1aa3ff;
}
</style>
