<template>
  <div v-if="show">
    <van-nav-bar
      fixed
      :title="title"
      :left-text="leftText"
      :right-text="rightText"
      :left-arrow="leftArrow"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :placeholder="false"
      :border="border"
    />
    <div
      v-if="placeholder"
      class="nav-bar-placeholder"
    ></div>
  </div>
</template>

<script>
import wx from 'jweixin-npm'

export default {
  props: {
    title: {
      default: '',
      type: String
    },
    leftText: {
      default: '',
      type: String
    },
    rightText: {
      default: '',
      type: String
    },
    leftArrow: {
      default: true,
      type: Boolean
    },
    placeholder: {
      default: true,
      type: Boolean,
    },
    border: {
      default: false,
      type: Boolean,
    },
    defaultClickLeft: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      show: true
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          var ua = navigator.userAgent.toLowerCase();
          var tag = true
          if(ua.match(/MicroMessenger/i)=="micromessenger") {
            wx.miniProgram.getEnv(function(res) {
              if (res.miniprogram) {
                document.title = newVal
                tag = false
              }
            })
          }
          this.show = tag
        }
      }
    }
  },
  methods: {
    onClickLeft() {
      if (this.defaultClickLeft) {
        this.$back();
      } else {
        this.$emit('clickLeft')
      }
    },
    onClickRight() {
      this.$emit('clickRight')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.van-nav-bar--fixed){
  z-index: 3;
}
:deep(.van-nav-bar__title) {
  color: #333333;
  font-size: 36px;
  font-weight: 400;
}
:deep(.van-nav-bar__left) {
  padding: 0 20px;
}
:deep(.van-nav-bar__right) {
  padding: 0 40px;
}

:deep(.van-icon) {
  color: #666666;
}
:deep(.van-nav-bar__text) {
  color: #00a1ff;
  font-size: 30px;
}
.nav-bar-placeholder{
    height: 92px;
}
</style>