<template>
<van-popover
  v-model="show"
  trigger="click"
  placement="bottom-start"
>
  <slot></slot>
  <template #reference>
    <div class="more">更多</div>
  </template>
</van-popover>
</template>

<script>
export default {
  data(){
    return {
      show: false,
    }
  },
  provide() {
    return {
      _more: this,
    };
  },
}
</script>

<style lang="scss" scoped>
.more-list {
  
}

.more {
  font-size: 26px;
  line-height: 38px;
  font-weight: 400;
  color: #999999;
}
</style>>