
<template>
  <van-dialog
    v-model="show"
    show-cancel-button
    confirmButtonColor="#04A3FC"
    cancelButtonColor="#999999"
    :confirmButtonText="confirmButtonText"
    :cancelButtonText="cancelButtonText"
    @confirm="confirm"
  >
    <div class="clear-dialog-text">
      <div v-if="title">{{title}}</div>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </van-dialog>
</template>

<script>

export default {
  name: "confirm",
  components: {

  },
  props: {
    //显示隐藏组件
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '确认操作？',
    },
    confirmButtonText:{
      type: String,
      default: '确认',
    },
    cancelButtonText:{
      type:String,
      default: '取消',
    },
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    confirm() {
      this.$emit('confirm');
    }
  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.clear-dialog-text {
  font-size: 36px;
  font-weight: bold;
  color: #666666;
  height: 149px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.van-dialog__cancel) {
  height: 122px;
  font-size: 36px;
  font-weight: 400;
}
:deep(.van-dialog__confirm) {
  height: 122px;
  font-size: 36px;
  font-weight: 400;
}
</style>
